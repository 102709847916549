const {
  h
} = require('preact');

const CameraIcon = require('./CameraIcon');

module.exports = _ref => {
  let {
    onSnapshot,
    i18n
  } = _ref;
  return h("button", {
    className: "uppy-u-reset uppy-c-btn uppy-Webcam-button uppy-Webcam-button--picture",
    type: "button",
    title: i18n('takePicture'),
    "aria-label": i18n('takePicture'),
    onClick: onSnapshot,
    "data-uppy-super-focusable": true
  }, CameraIcon());
};