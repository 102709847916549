module.exports = {
  strings: {
    startCapturing: 'Begin screen capturing',
    stopCapturing: 'Stop screen capturing',
    submitRecordedFile: 'Submit recorded file',
    streamActive: 'Stream active',
    streamPassive: 'Stream passive',
    micDisabled: 'Microphone access denied by user',
    recording: 'Recording'
  }
};