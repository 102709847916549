const {
  h
} = require('preact');

const formatSeconds = require('./formatSeconds');

module.exports = function RecordingLength(_ref) {
  let {
    recordingLengthSeconds,
    i18n
  } = _ref;
  const formattedRecordingLengthSeconds = formatSeconds(recordingLengthSeconds);
  return h("span", {
    "aria-label": i18n('recordingLength', {
      recording_length: formattedRecordingLengthSeconds
    })
  }, formattedRecordingLengthSeconds);
};