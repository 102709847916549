/**
 * Little AbortController proxy module so we can swap out the implementation easily later.
 */
exports.AbortController = globalThis.AbortController;
exports.AbortSignal = globalThis.AbortSignal;

exports.createAbortError = function (message) {
  if (message === void 0) {
    message = 'Aborted';
  }

  return new DOMException(message, 'AbortError');
};